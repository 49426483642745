import { ConfigCrmModal } from '@supersonic/config';
import { ASSETS_PREFIX } from '../constants';

const imgPrefix = `${ASSETS_PREFIX}/images`;

const crmModal: ConfigCrmModal = {
  navMenuTitle: 'Never miss a deal.',
  navMenuContent:
    'Join our email list for first dibs on the best money saving deals.',
  navMenuButtonText: 'Sign up',
  navMenuButtonColorClass: 'text-black',
  closeButtonColorMobile: 'black',
  closeButtonColorDesktop: 'white',
  closeButtonIndentedMobile: true,
  closeButtonIndentedDesktop: false,
  title: 'Sign up and save!',
  logo: 'travelsupermarket',
  benefitsTitle: 'Join now for:',
  benefits: [
    {
      image: `${imgPrefix}/illustrations/personaliseddeals.svg`,
      alt: '',
      name: 'Personalised deals',
    },
    {
      image: `${imgPrefix}/illustrations/experttraveladvice.svg`,
      alt: '',
      name: 'Expert travel advice',
    },
    {
      image: `${imgPrefix}/illustrations/holidayprizes.svg`,
      alt: '',
      name: 'Holiday prizes',
    },
  ],
  emailFieldPlaceholder: 'Enter email',
  submitButtonText: 'Sign up',
  consentText:
    'TravelSupermarket Limited wish to send you marketing emails. If you consent, please tick to confirm.',
  privacyPolicyLink: '/en-gb/privacy-policy',
  privacyPolicyLinkColor: 'primary_darker1',
  successImage: {
    image: `${imgPrefix}/illustrations/emailconfirmation.svg`,
    alt: '',
  },
  successCtaText: 'Close window',
  successTitleFirstLine: 'Congratulations,',
  successTitleSecondLine: "you're in!",
};
export default crmModal;
