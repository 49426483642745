import { ConfigHeader } from '@supersonic/config';

export const blogHeader: ConfigHeader = {
  logo: 'travelsupermarket',
  blogSearchField: true,
  emailModalButton: false,
  navigation: {
    links: [
      {
        channel: 'Blog',
        channelLink: '/en-gb/blog/',
        subnav: [
          {
            title: 'Travel advice',
            links: [
              {
                text: 'Last-minute booking tips',
                link: '/en-gb/blog/travel-advice/when-is-the-best-time-to-book-a-cheap-last-minute-holiday/',
                secondaryLinks: [
                  { text: 'test 1', link: '/test-1' },
                  { text: 'test 2', link: '/test-2' },
                ],
              },
              {
                text: 'Money-saving flight hacks',
                link: '/en-gb/blog/travel-advice/ask-the-experts-flight-and-airport-hacks-that-save-time-and-money/',
                secondaryLinks: [
                  { text: 'test 1', link: '/test-1' },
                  { text: 'test 2', link: '/test-2' },
                ],
              },
              {
                text: 'Long list',
                link: '/en-gb/blog/travel-advice/passport-post-youd-better-hope/',
                secondaryLinks: [
                  { text: 'test 1', link: '/test-1' },
                  { text: 'test 2', link: '/test-2' },
                  { text: 'test 3', link: '/test-2' },
                  { text: 'test 4', link: '/test-2' },
                  { text: 'test 5', link: '/test-2' },
                  { text: 'test 6', link: '/test-2' },
                  { text: 'test 7', link: '/test-2' },
                  { text: 'test 8', link: '/test-2' },
                  { text: 'test 9', link: '/test-2' },
                  { text: 'test 10', link: '/test-2' },
                ],
              },
              {
                text: 'Not a link',
                secondaryLinks: [
                  { text: 'test 1', link: '/test-1' },
                  { text: 'test 2', link: '/test-2' },
                ],
              },
              {
                text: 'Hand luggage tips',
                link: '/en-gb/blog/travel-advice/hand-luggage-tips/',
              },
              {
                text: 'Travel insurance FAQs',
                link: '/en-gb/blog/travel-advice/your-travel-insurance-questions-answered/',
              },
              {
                text: 'When to book holidays',
                link: '/en-gb/blog/travel-advice/holiday-vs-holiday-book-now-book-later/',
              },
              {
                text: 'Sustainable travel',
                link: '/en-gb/blog/travel-advice/how-to-make-your-holiday-more-sustainable/',
              },
            ],
          },
          {
            title: 'Holiday inspiration',
            links: [
              {
                text: 'Best all-inclusive escapes',
                link: '/en-gb/blog/inspiration/best-all-inclusive-destinations/',
                secondaryLinks: [
                  { text: 'test 1', link: '/test-1' },
                  { text: 'test 2', link: '/test-2' },
                ],
              },
              {
                text: 'Winter sun destinations',
                link: '/en-gb/blog/inspiration/top-10-winter-sun-holiday-destinations/',
              },
              {
                text: 'Winter city breaks',
                link: '/en-gb/blog/inspiration/10-of-the-best-winter-city-breaks/',
              },
              {
                text: 'Staycation destinations',
                link: '/en-gb/blog/inspiration/great-britains-top-10-staycation-destinations/',
              },
              {
                text: "Europe's best cities",
                link: '/en-gb/blog/inspiration/best-cities-to-visit-in-europe-this-year/',
              },
              {
                text: 'Top 10 US cities',
                link: '/en-gb/blog/inspiration/top-ten-cities-usa/',
              },
              {
                text: 'Solo travel ideas',
                link: '/en-gb/blog/inspiration/10-best-destinations-first-time-solo-travel/',
              },
              {
                text: 'Alternative Spain',
                link: '/en-gb/blog/inspiration/alternative-spanish-cities-to-visit-after-barcelona/',
              },
            ],
          },
          {
            title: 'Expert guides',
            links: [
              {
                text: 'Balearics vs Canaries',
                link: '/en-gb/blog/guides/balearic-vs-canaries/',
              },
              {
                text: "Turkey's top resorts",
                link: '/en-gb/blog/guides/talking-turkey-which-resort-is-right-for-you/',
              },
              {
                text: 'Where to go in Greece',
                link: '/en-gb/blog/guides/where-to-go-in-greece-for-every-type-of-traveller/',
              },
              {
                text: 'Secret Algarve',
                link: '/en-gb/blog/guides/secret-algarve-how-to-escape-the-tourist-trail/',
              },
              {
                text: 'Caribbean islands guide',
                link: '/en-gb/blog/guides/which-caribbean-island-is-best/',
              },
              {
                text: 'Where to stay in Mexico',
                link: '/en-gb/blog/guides/beyond-cancun-great-resorts-in-mexico/',
              },
              {
                text: "Thailand's best islands",
                link: '/en-gb/blog/guides/best-islands-thailand/',
              },
              {
                text: 'New York like a local',
                link: '/en-gb/blog/guides/new-york-survival-guide/',
              },
            ],
          },
          {
            title: 'Where to go when',
            links: [
              {
                text: 'Where to go in January',
                link: '/en-gb/blog/inspiration/where-to-go-january/',
              },
              {
                text: 'Where to go in February',
                link: '/en-gb/blog/inspiration/where-to-go-february/',
              },
              {
                text: 'Where to go in March',
                link: '/en-gb/blog/inspiration/where-to-go-in-march/',
              },
              {
                text: 'Where to go in April',
                link: '/en-gb/blog/inspiration/where-to-go-april/',
              },
              {
                text: 'Where to go in May',
                link: '/en-gb/blog/inspiration/where-to-go-may/',
              },
              {
                text: 'Where to go in June',
                link: '/en-gb/blog/inspiration/where-to-go-june/',
              },
              {
                text: 'Where to go in July',
                link: '/en-gb/blog/inspiration/where-to-go-july/',
              },
              {
                text: 'Where to go in August',
                link: '/en-gb/blog/inspiration/where-to-go-august/',
              },
              {
                text: 'Where to go in September',
                link: '/en-gb/blog/inspiration/where-to-go-september/',
              },
              {
                text: 'Where to go in October',
                link: '/en-gb/blog/inspiration/where-to-go-october/',
              },
              {
                text: 'Where to go in November',
                link: '/en-gb/blog/inspiration/where-to-go-november/',
              },
              {
                text: 'Where to go in December',
                link: '/en-gb/blog/inspiration/where-to-go-december/',
              },
            ],
          },
          {
            title: 'Budget travel ',
            links: [
              {
                text: 'Money-saving travel tips',
                link: '/en-gb/blog/travel-advice/10-tips-for-getting-more-out-of-your-holiday-money/',
              },
              {
                text: 'UK holidays for less',
                link: '/en-gb/blog/travel-advice/how-to-save-on-a-uk-holiday/',
              },
              {
                text: 'Cheap holiday extras',
                link: '/en-gb/blog/travel-advice/luxury-holiday-extras-for-less/',
              },
              {
                text: 'Cheap city breaks in Europe',
                link: '/en-gb/blog/inspiration/7-eastern-european-cities-that-are-still-cheap/',
              },
              {
                text: 'Budget Canary Islands',
                link: '/en-gb/blog/guides/how-to-do-the-canary-islands-on-a-budget/',
              },
              {
                text: 'Budget Majorca',
                link: '/en-gb/blog/guides/how-to-do-majorca-on-a-budget/',
              },
              {
                text: 'Budget Crete',
                link: '/en-gb/blog/guides/how-to-do-crete-on-a-budget/',
              },
              {
                text: 'Budget Algarve',
                link: '/en-gb/blog/guides/how-to-do-the-algarve-on-a-budget/',
              },
            ],
          },
          {
            title: 'Family holidays ',
            links: [
              {
                text: 'Money-saving tips for families',
                link: '/en-gb/blog/travel-advice/saving-money-on-family-holidays/',
              },
              {
                text: 'Best family destinations',
                link: '/en-gb/blog/inspiration/top-ten-family-holiday-destinations/',
              },
              {
                text: 'Kid-friendly cities',
                link: '/en-gb/blog/inspiration/7-child-friendly-city-breaks/',
              },
              {
                text: 'Family staycation ideas',
                link: '/en-gb/blog/inspiration/best-uk-family-staycation-destinations/',
              },
              {
                text: 'Alternative family holidays',
                link: '/en-gb/blog/inspiration/best-alternative-family-holidays/',
              },
              {
                text: 'Family holiday rip-offs',
                link: '/en-gb/blog/travel-advice/7-ripoffs-when-travelling-with-children-and-how-to-avoid-them/',
              },
              {
                text: 'Kids luggage allowances',
                link: '/en-gb/blog/travel-advice/travelling-with-kids-know-your-luggage-allowances/',
              },
              {
                text: 'Term time travel explained',
                link: '/en-gb/blog/travel-advice/can-i-take-my-kids-on-holiday-during-term-time/',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'Last-minute booking tips',
            subText: '',
            image:
              'navigation-cards/blog/when-is-the-best-time-to-book-a-cheap-last-minute-holiday.jpg',
            link: '/en-gb/blog/travel-advice/when-is-the-best-time-to-book-a-cheap-last-minute-holiday/',
          },
          {
            tag: '',
            title: 'Best all-inclusive escapes',
            subText: '',
            image: 'navigation-cards/blog/best-all-inclusive-destinations.jpg',
            link: '/en-gb/blog/inspiration/best-all-inclusive-destinations/',
          },
        ],
      },
    ],
  },
};
