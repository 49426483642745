import { ConfigCrmSignUp } from '@supersonic/config';

const crmSignUp: ConfigCrmSignUp = {
  title: 'Sign up and save on your next holiday',
  subTitle:
    'Be a savvy traveller and get top deals to your inbox, expert travel advice and the chance to win holidays',
  emailFieldPlaceholder: 'Your email address',
  submitButtonText: 'Sign up',
  consentCompanyName: 'TravelSupermarket Limited',
  consentText:
    'TravelSupermarket Limited wish to send you marketing emails. If you consent, please tick to confirm.',
  legalText:
    "We won't pass your details on to anyone else. By clicking the sign up button you agree to our",
  privacyPolicyLink: '/en-gb/privacy-policy/',
  privacyPolicyLinkColor: 'primary_darker1',
  termsOfUseLink: '/en-gb/terms/',
  termsOfUseLinkColor: 'primary_darker1',
};
export default crmSignUp;
