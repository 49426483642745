import { ConfigTravelInsurance } from '@supersonic/config';

const travelInsurance: ConfigTravelInsurance = {
  title: 'Cover from just £2.45*',
  description:
    '*Based on an individual aged 30 with no pre-existing medical conditions taking single trip cover for 3 days to France. Cover starts on August 10th, 2024 and ends on August 13th, 2024. Price correct as of August 15th, 2024.',
  ctaText: 'Get a new quote',
  ctaUrl: 'https://travelinsurance.travelsupermarket.com/',
};

export default travelInsurance;
