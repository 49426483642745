import { SecondaryNavTheme, ConfigSecondaryNav } from '@supersonic/config';
import { withBlogPrefix } from '../utils';

export const secondaryNavTheme: SecondaryNavTheme = {
  backgroundColor: 'secondary_darker1',
  outlineColor: 'white',
  sectionBackgroundColor: 'secondary_darker2',
  sectionTextHoverColor: 'secondary_lighter2',
  sectionTitleColor: 'secondary_lighter3',
  dividerColor: 'secondary',
  mobileNavButton: {
    borderColor: 'secondary',
    activeBackgroundColor: 'secondary_darker2',
  },
};

export const secondaryNav: ConfigSecondaryNav = {
  mainLink: {
    link: withBlogPrefix('/'),
    text: 'Blog',
  },
  showSearchBar:
    (process.env.SHOW_SEARCH_BAR_IN_SECONDARY_NAV as string) === 'true',
  items: [
    {
      text: 'Collection',
      title: 'Holiday types',
      links: [
        { text: 'Beach', link: withBlogPrefix('/beach/') },
        { text: 'City breaks', link: withBlogPrefix('/city-breaks/') },
        { text: 'Family', link: withBlogPrefix('/family/') },
        { text: 'Staycation', link: withBlogPrefix('/staycation/') },
        { text: 'Summer', link: withBlogPrefix('/summer/') },
        { text: 'Winter sun', link: withBlogPrefix('/winter-sun/') },
        { text: 'Budget', link: withBlogPrefix('/budget/') },
        { text: 'Long-haul', link: withBlogPrefix('/long-haul/') },
      ],
    },
    {
      text: 'Destination',
      title: 'Most popular',
      ctaLink: {
        text: 'View all destinations',
        link: withBlogPrefix('/destinations/'),
      },
      links: [
        {
          text: 'Balearic Islands',
          link: withBlogPrefix('/spain/balearic-islands/'),
          mobileOnlyText: 'Balearic Islands - All',
          additionalLinks: [
            {
              text: 'Majorca',
              link: withBlogPrefix('/spain/balearic-islands/majorca/'),
            },
            {
              text: 'Ibiza',
              link: withBlogPrefix('/spain/balearic-islands/ibiza/'),
            },
            {
              text: 'Menorca',
              link: withBlogPrefix('/spain/balearic-islands/menorca/'),
            },
            {
              text: 'Formentera',
              link: withBlogPrefix('/spain/balearic-islands/formentera/'),
            },
          ],
        },
        {
          text: 'Canary Islands',
          link: withBlogPrefix('/spain/canary-islands/'),
          mobileOnlyText: 'Canary Islands - All',
          additionalLinks: [
            {
              text: 'Tenerife',
              link: withBlogPrefix('/spain/canary-islands/tenerife/'),
            },
            {
              text: 'Lanzarote',
              link: withBlogPrefix('/spain/canary-islands/lanzarote/'),
            },
            {
              text: 'Gran Canaria',
              link: withBlogPrefix('/spain/canary-islands/gran-canaria/'),
            },
            {
              text: 'Fuerteventura',
              link: withBlogPrefix('/spain/canary-islands/fuerteventura/'),
            },
            {
              text: 'La Gomera',
              link: withBlogPrefix('/spain/canary-islands/la-gomera/'),
            },
            {
              text: 'La Palma',
              link: withBlogPrefix('/spain/canary-islands/la-palma/'),
            },
          ],
        },
        {
          text: 'Italy',
          link: withBlogPrefix('/italy/'),
          mobileOnlyText: 'Italy - All',
          additionalLinks: [
            {
              text: 'Rome',
              link: withBlogPrefix('/italy/rome/'),
            },
            {
              text: 'Venice',
              link: withBlogPrefix('/italy/venice/'),
            },
            {
              text: 'Sicily',
              link: withBlogPrefix('/italy/sicily/'),
            },
            {
              text: 'Sardinia',
              link: withBlogPrefix('/italy/sardinia/'),
            },
          ],
        },
        { text: 'Caribbean', link: withBlogPrefix('/caribbean/') },
        {
          text: 'Greece',
          link: withBlogPrefix('/greece/'),
          mobileOnlyText: 'Greece - All',
          additionalLinks: [
            {
              text: 'Crete',
              link: withBlogPrefix('/greece/crete/'),
            },
            {
              text: 'Corfu',
              link: withBlogPrefix('/greece/corfu/'),
            },
            {
              text: 'Kefalonia',
              link: withBlogPrefix('/greece/kefalonia/'),
            },
            {
              text: 'Athens',
              link: withBlogPrefix('/greece/athens/'),
            },
          ],
        },
        {
          text: 'Spain',
          link: withBlogPrefix('/spain/'),
          mobileOnlyText: 'Spain - All',
          additionalLinks: [
            {
              text: 'Barcelona',
              link: withBlogPrefix('/spain/barcelona/'),
            },
            {
              text: 'Costa del Sol',
              link: withBlogPrefix('/spain/costa-del-sol/'),
            },
            {
              text: 'Costa Dorada',
              link: withBlogPrefix('/spain/costa-dorada/'),
            },
            {
              text: 'Costa Brava',
              link: withBlogPrefix('/spain/costa-brava/'),
            },
          ],
        },
        { text: 'Turkey', link: withBlogPrefix('/turkey/') },
        {
          text: 'Portugal',
          link: withBlogPrefix('/portugal/'),
          mobileOnlyText: 'Portugal - All',
          additionalLinks: [
            {
              text: 'Algarve',
              link: withBlogPrefix('/portugal/algarve/'),
            },
            {
              text: 'Madeira',
              link: withBlogPrefix('/portugal/madeira/'),
            },
          ],
        },
        { text: 'Croatia', link: withBlogPrefix('/croatia/') },
        {
          text: 'United Kingdom',
          link: withBlogPrefix('/united-kingdom/'),
          mobileOnlyText: 'United Kingdom - All',
          additionalLinks: [
            {
              text: 'London',
              link: withBlogPrefix('/united-kingdom/england/london/'),
            },
            {
              text: 'Wales',
              link: withBlogPrefix('/united-kingdom/wales/'),
            },
            {
              text: 'Scotland',
              link: withBlogPrefix('/united-kingdom/scotland/'),
            },
            {
              text: 'Cornwall',
              link: withBlogPrefix('/united-kingdom/england/cornwall/'),
            },
          ],
        },
        {
          text: 'United States',
          link: withBlogPrefix('/united-states/'),
          mobileOnlyText: 'United States - All',
          additionalLinks: [
            {
              text: 'New York',
              link: withBlogPrefix('/united-states/new-york/'),
            },
            {
              text: 'Florida',
              link: withBlogPrefix('/united-states/florida/'),
            },
            {
              text: 'Las Vegas',
              link: withBlogPrefix('/united-states/las-vegas/'),
            },
          ],
        },
        { text: 'Dubai', link: withBlogPrefix('/united-arab-emirates/dubai/') },
      ],
    },
    {
      text: 'Inspiration',
      link: withBlogPrefix('/inspiration/'),
    },
    {
      text: 'Travel tips',
      title: 'Tools to help you travel smarter',
      links: [
        { text: 'Car hire', link: withBlogPrefix('/car-hire/') },
        { text: 'Flights', link: withBlogPrefix('/flights/') },
        {
          text: 'Travel insurance',
          link: withBlogPrefix('/travel-insurance/'),
        },
        { text: 'Holiday money', link: withBlogPrefix('/holiday-money/') },
        { text: 'Packing guides', link: withBlogPrefix('/packing-guides/') },
        {
          text: 'Holiday planning',
          link: withBlogPrefix('/holiday-planning/'),
        },
      ],
    },
    {
      text: 'Latest travel news',
      link: withBlogPrefix('/travel-news/'),
    },
    {
      text: 'Meet our experts',
      link: withBlogPrefix('/authors/'),
    },
  ],
};
