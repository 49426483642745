import { LineItemTheme } from '@supersonic/config';

export const lineItemTheme: LineItemTheme = {
  primaryColor: 'secondary',
  light: {
    bgHoverColor: 'secondary_lighter6',
  },
  dark: {
    bgColor: 'secondary_darker2',
    bgHoverColor: 'secondary_darker1',
  },
  invert: {
    bgColor: 'secondary',
  },
};
