import { CarHireGadgetTheme, ConfigCarHireGadget } from '@supersonic/config';

const carHireGadget: ConfigCarHireGadget = {
  backTab: {
    enabled: true,
    mediaAlphaScriptUrl: process.env.MEDIA_ALPHA_SCRIPT_URL as string,
    desktopPlacementId: 'yI-4pcjrIh24J6zuFScV3bFXExPcwg',
    mobilePlacementId: '9pOpcpLLFpnnV9jkdzriY4xS0oOBrw',
    timestampLocalStorageKey:
      'travelsupermarket-media-alpha-back-tab-timestamp',
    intervalMs: 1000 * 60 * 60, // 1 hour
  },
  carHireResultsUrlStem: '/en-gb/car-hire/results',
  destinationSelector: {
    autocompleteApi: process.env.CAR_HIRE_AUTOCOMPLETE_API_URL as string,
  },
};

export const carHireGadgetTheme: CarHireGadgetTheme = {
  driverAgeInfoIconColor: 'secondary',
};

export default carHireGadget;
