import { DestinationFinderTheme } from '@supersonic/config';

export const destinationFinderTheme: DestinationFinderTheme = {
  temperatureIconBgColor: 'primary_lighter2',
  flightTimeIconBgColor: 'primary',
  dealCardPriceColor: 'primary',
  promo: {
    title: 'Need more holiday inspiration?',
    text: 'Let us know what you’re looking for and we’ll narrow down the best destinations and deals for your holiday!',
    buttonText: 'Inspire me',
    url: '/en-gb/holidays/find-your-holiday/MAN+BHX+BRS+LGW+NCL+LBA+EMA+EDI/2024-07-06/7/2/',
    imageOne: {
      borderColor: 'primary_darker1',
      path: 'https://cdn.sanity.io/images/hqzqrzyr/production-travelsupermarket/5865076f18bec8841baa4f802c89b98b84f69f4d-5921x3947.jpg',
    },
    imageTwo: {
      borderColor: 'primary',
      path: 'https://cdn.sanity.io/images/hqzqrzyr/production-travelsupermarket/49871a736f5e6df63ce960df7f61185099b515fb-5027x3790.jpg',
    },
    imageThree: {
      borderColor: 'primary_lighter1',
      path: 'https://cdn.sanity.io/images/hqzqrzyr/production-travelsupermarket/d0e118af7b99fa1d610db90f97fc9959fc1c2918-5616x3744.jpg',
    },
  },
};
