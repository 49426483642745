import { ConfigHeader, HeaderTheme, NavigationTheme } from '@supersonic/config';

export const header: ConfigHeader = {
  logo: 'travelsupermarket',
  blogSearchField: false,
  emailModalButton: true,
  navigation: {
    links: [
      {
        channel: 'Holidays',
        channelLink: '/en-gb/holidays/',
        subnav: [
          {
            title: 'Holidays',
            links: [
              {
                text: 'Last-minute',
                link: '/en-gb/holidays/last-minute/',
              },
              {
                text: 'All-inclusive',
                link: '/en-gb/holidays/all-inclusive/',
              },
              {
                text: 'City breaks',
                link: '/en-gb/holidays/city-breaks/',
              },
              {
                text: 'UK holidays',
                link: '/en-gb/hotels/uk-holidays/',
              },
              {
                text: 'Summer 2025',
                link: '/en-gb/holidays/summer/',
              },
              {
                text: 'Beach holidays',
                link: '/en-gb/holidays/beach-holidays/',
              },
              {
                text: 'Cruises',
                link: '/en-gb/cruises/',
              },
            ],
          },
          {
            title: 'City breaks',
            links: [
              {
                text: 'Amsterdam',
                link: '/en-gb/holidays/city-breaks/amsterdam/',
              },
              {
                text: 'Rome',
                link: '/en-gb/holidays/city-breaks/rome/',
              },
              {
                text: 'Barcelona',
                link: '/en-gb/holidays/city-breaks/barcelona/',
              },
              {
                text: 'Paris',
                link: '/en-gb/holidays/city-breaks/paris/',
              },
              {
                text: 'New York',
                link: '/en-gb/holidays/city-breaks/new-york/',
              },
              {
                text: 'Venice',
                link: '/en-gb/holidays/city-breaks/venice/',
              },
            ],
          },
          {
            title: 'All-inclusive',
            links: [
              {
                text: 'Tenerife',
                link: '/en-gb/holidays/spain/canary-islands/tenerife/',
              },
              {
                text: 'Cape Verde',
                link: '/en-gb/holidays/cape-verde/',
              },
              {
                text: 'Dubai',
                link: '/en-gb/holidays/united-arab-emirates/dubai/',
              },
              {
                text: 'Cyprus',
                link: '/en-gb/holidays/cyprus/',
              },
              {
                text: 'Florida',
                link: '/en-gb/holidays/united-states/florida/',
              },
              {
                text: 'Cuba',
                link: '/en-gb/holidays/caribbean/cuba/',
              },
            ],
          },
          {
            title: 'Beach',
            links: [
              {
                text: 'Maldives',
                link: '/en-gb/holidays/maldives/',
              },
              {
                text: 'Benidorm',
                link: '/en-gb/holidays/spain/benidorm/',
              },
              {
                text: 'Majorca',
                link: '/en-gb/holidays/spain/balearic-islands/majorca/',
              },
              {
                text: 'Canary Islands',
                link: '/en-gb/holidays/spain/canary-islands/',
              },
              {
                text: 'Turkey',
                link: '/en-gb/holidays/turkey/',
              },
              {
                text: 'Algarve',
                link: '/en-gb/holidays/portugal/algarve/',
              },
            ],
          },
          {
            title: 'Long-haul',
            links: [
              {
                text: 'Bali',
                link: '/en-gb/holidays/indonesia/bali/',
              },
              {
                text: 'Barbados',
                link: '/en-gb/holidays/caribbean/barbados/',
              },
              {
                text: 'Mexico',
                link: '/en-gb/holidays/mexico/',
              },
              {
                text: 'New York',
                link: '/en-gb/holidays/united-states/new-york/',
              },
              {
                text: 'Las Vegas',
                link: '/en-gb/holidays/united-states/las-vegas/',
              },
              {
                text: 'Thailand',
                link: '/en-gb/holidays/thailand/',
              },
            ],
          },
          {
            title: 'Inspiration',
            links: [
              {
                text: 'Top holiday deals',
                link: '/en-gb/holidays/deals/',
              },
              {
                text: 'European city breaks',
                link: '/en-gb/holidays/city-breaks/european-cities/',
              },
              {
                text: 'Family holidays',
                link: '/en-gb/holidays/top-family-holiday-destinations/',
              },
              {
                text: "Where's hot",
                link: '/en-gb/holidays/where-is-hot/',
              },
              {
                text: 'Free child places',
                link: '/en-gb/holidays/free-child-places/',
              },
              {
                text: 'ATOL-protected holidays',
                link: '/en-gb/holidays/atol-protection/',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'All-inclusive holidays',
            subText: '',
            image: 'navigation-cards/holidays/all-inclusive.jpg',
            link: '/en-gb/holidays/all-inclusive/',
          },
          {
            tag: '',
            title: 'City breaks',
            subText: '',
            image: 'navigation-cards/holidays/city-breaks.jpg',
            link: '/en-gb/holidays/city-breaks/',
          },
        ],
      },
      {
        channel: 'Car hire',
        channelLink: '/en-gb/car-hire/',
        subnav: [
          {
            title: 'Car hire types',
            links: [
              {
                text: 'Long-term',
                link: '/en-gb/car-hire/long-term/',
              },
              {
                text: 'Minibus and people carrier',
                link: '/en-gb/car-hire/minibus-hire/',
              },
              {
                text: 'One-way',
                link: '/en-gb/car-hire/one-way/',
              },
              {
                text: 'Automatic',
                link: '/en-gb/car-hire/automatic/',
              },
              {
                text: 'Car hire excess',
                link: '/en-gb/car-hire/excess-insurance/',
              },
            ],
          },
          {
            title: 'UK',
            links: [
              {
                text: 'All UK',
                link: '/en-gb/car-hire/united-kingdom/',
              },
              {
                text: 'Heathrow',
                link: '/en-gb/car-hire/united-kingdom/england/london/heathrow/',
              },
              {
                text: 'Glasgow',
                link: '/en-gb/car-hire/united-kingdom/scotland/glasgow/',
              },
              {
                text: 'Belfast',
                link: '/en-gb/car-hire/united-kingdom/northern-ireland/belfast/',
              },
              {
                text: 'Edinburgh',
                link: '/en-gb/car-hire/united-kingdom/scotland/edinburgh/',
              },
              {
                text: 'Manchester',
                link: '/en-gb/car-hire/united-kingdom/england/manchester/',
              },
            ],
          },
          {
            title: 'Europe',
            links: [
              {
                text: 'Spain',
                link: '/en-gb/car-hire/spain/',
              },
              {
                text: 'Italy',
                link: '/en-gb/car-hire/italy/',
              },
              {
                text: 'Malta',
                link: '/en-gb/car-hire/malta/',
              },
              {
                text: 'Portugal',
                link: '/en-gb/car-hire/portugal/',
              },
              {
                text: 'France',
                link: '/en-gb/car-hire/france/',
              },
              {
                text: 'Croatia',
                link: '/en-gb/car-hire/croatia/',
              },
            ],
          },
          {
            title: 'Top beach destinations',
            links: [
              {
                text: 'Faro',
                link: '/en-gb/car-hire/portugal/algarve/faro/',
              },
              {
                text: 'Alicante',
                link: '/en-gb/car-hire/spain/alicante/',
              },
              {
                text: 'Majorca',
                link: '/en-gb/car-hire/spain/balearic-islands/majorca/',
              },
              {
                text: 'Malaga',
                link: '/en-gb/car-hire/spain/costa-del-sol/malaga/',
              },
              {
                text: 'Tenerife',
                link: '/en-gb/car-hire/spain/canary-islands/tenerife/',
              },
              {
                text: 'Paphos',
                link: '/en-gb/car-hire/cyprus/paphos/',
              },
            ],
          },
          {
            title: 'City breaks',
            links: [
              {
                text: 'Dublin',
                link: '/en-gb/car-hire/ireland/dublin/',
              },
              {
                text: 'Nice',
                link: '/en-gb/car-hire/france/nice/',
              },
              {
                text: 'Barcelona',
                link: '/en-gb/car-hire/spain/barcelona/',
              },
              {
                text: 'Rome',
                link: '/en-gb/car-hire/italy/rome/',
              },
              {
                text: 'Paris',
                link: '/en-gb/car-hire/france/paris/',
              },
              {
                text: 'Pisa',
                link: '/en-gb/car-hire/italy/tuscany/pisa/',
              },
            ],
          },
          {
            title: 'Car hire advice',
            links: [
              {
                text: 'Consumer rights',
                link: '/en-gb/blog/travel-advice/disputing-car-hire-charges-what-are-your-rights/',
              },
              {
                text: 'Rip-offs to avoid',
                link: '/en-gb/blog/travel-advice/7-car-hire-ripoffs-to-avoid/',
              },
              {
                text: 'Cheapest time to book',
                link: '/en-gb/blog/travel-advice/revealed-the-cheapest-time-to-book-a-hire-car-is/',
              },
              {
                text: 'Car hire classes explained',
                link: '/en-gb/blog/travel-advice/car-hire-classes-explained/',
              },
              {
                text: 'Excess insurance explained',
                link: '/en-gb/blog/travel-advice/what-is-car-hire-excess-insurance-and-do-i-need-it/',
              },
              {
                text: 'All destinations',
                link: '/en-gb/car-hire/sitemap/',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'Long-term',
            subText: '',
            image: 'navigation-cards/car-hire/long-term.jpg',
            link: '/en-gb/car-hire/long-term/',
          },
          {
            tag: '',
            title: 'All UK',
            subText: '',
            image: 'navigation-cards/car-hire/united-kingdom.jpg',
            link: '/en-gb/car-hire/united-kingdom/',
          },
        ],
      },
      {
        channel: 'Flights',
        channelLink: '/en-gb/flights/',
        subnav: [
          {
            title: 'Popular destinations',
            links: [
              {
                text: 'Last-minute flights',
                link: '/en-gb/flights/last-minute/',
              },
              {
                text: 'Australia',
                link: '/en-gb/flights/australia/',
              },
              {
                text: 'India',
                link: '/en-gb/flights/india/',
              },
              {
                text: 'Tenerife',
                link: '/en-gb/flights/spain/canary-islands/tenerife/',
              },
              {
                text: 'Orlando',
                link: '/en-gb/flights/united-states/orlando/',
              },
              {
                text: 'New York',
                link: '/en-gb/flights/united-states/new-york/',
              },
            ],
          },
          {
            title: 'UK',
            links: [
              {
                text: 'Belfast',
                link: '/en-gb/flights/united-kingdom/northern-ireland/belfast/',
              },
              {
                text: 'Edinburgh',
                link: '/en-gb/flights/united-kingdom/scotland/edinburgh/',
              },
              {
                text: 'Glasgow',
                link: '/en-gb/flights/united-kingdom/scotland/glasgow/',
              },
              {
                text: 'Manchester',
                link: '/en-gb/flights/united-kingdom/england/manchester/',
              },
              {
                text: 'London',
                link: '/en-gb/flights/united-kingdom/england/london/',
              },
              {
                text: 'Aberdeen',
                link: '/en-gb/flights/united-kingdom/scotland/aberdeen/',
              },
            ],
          },
          {
            title: 'Spain',
            links: [
              {
                text: 'Alicante',
                link: '/en-gb/flights/spain/alicante/',
              },
              {
                text: 'Malaga',
                link: '/en-gb/flights/spain/costa-del-sol/malaga/',
              },
              {
                text: 'Lanzarote',
                link: '/en-gb/flights/spain/canary-islands/lanzarote/',
              },
              {
                text: 'Benidorm',
                link: '/en-gb/flights/spain/benidorm/',
              },
              {
                text: 'Murcia',
                link: '/en-gb/flights/spain/murcia/',
              },
              {
                text: 'Spain',
                link: '/en-gb/flights/spain/',
              },
            ],
          },
          {
            title: 'USA',
            links: [
              {
                text: 'Orlando',
                link: '/en-gb/flights/united-states/orlando/',
              },
              {
                text: 'New York',
                link: '/en-gb/flights/united-states/new-york/',
              },
              {
                text: 'Florida',
                link: '/en-gb/flights/united-states/florida/',
              },
              {
                text: 'Las Vegas',
                link: '/en-gb/flights/united-states/las-vegas/',
              },
              {
                text: 'Los Angeles',
                link: '/en-gb/flights/united-states/los-angeles/',
              },
              {
                text: 'All USA',
                link: '/en-gb/flights/united-states/',
              },
            ],
          },
          {
            title: 'City breaks',
            links: [
              {
                text: 'Amsterdam',
                link: '/en-gb/flights/netherlands/amsterdam/',
              },
              {
                text: 'Rome',
                link: '/en-gb/flights/italy/rome/',
              },
              {
                text: 'Lisbon',
                link: '/en-gb/flights/portugal/lisbon/',
              },
              {
                text: 'Paris',
                link: '/en-gb/flights/france/paris/',
              },
              {
                text: 'Barcelona',
                link: '/en-gb/flights/spain/barcelona/',
              },
              {
                text: 'Dubrovnik',
                link: '/en-gb/flights/croatia/dubrovnik/',
              },
            ],
          },
          {
            title: 'Long-haul',
            links: [
              {
                text: 'Thailand',
                link: '/en-gb/flights/thailand/',
              },
              {
                text: 'Barbados',
                link: '/en-gb/flights/caribbean/barbados/',
              },
              {
                text: 'New Zealand',
                link: '/en-gb/flights/new-zealand/',
              },
              {
                text: 'Canada',
                link: '/en-gb/flights/canada/',
              },
              {
                text: 'Sri Lanka',
                link: '/en-gb/flights/sri-lanka/',
              },
              {
                text: 'All destinations',
                link: '/en-gb/flights/sitemap/',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'New York',
            subText: '',
            image: 'navigation-cards/flights/united-states-new-york.jpg',
            link: '/en-gb/flights/united-states/new-york/',
          },
          {
            tag: '',
            title: 'Alicante',
            subText: '',
            image: 'navigation-cards/flights/spain-alicante.jpg',
            link: '/en-gb/flights/spain/alicante/',
          },
        ],
      },
      {
        channel: 'Hotels',
        channelLink: '/en-gb/hotels/',
        subnav: [
          {
            title: 'UK',
            links: [
              {
                text: 'London',
                link: '/en-gb/hotels/united-kingdom/england/london/',
              },
              {
                text: 'Blackpool',
                link: '/en-gb/hotels/united-kingdom/england/blackpool/',
              },
              {
                text: 'Edinburgh',
                link: '/en-gb/hotels/united-kingdom/scotland/edinburgh/',
              },
              {
                text: 'Liverpool',
                link: '/en-gb/hotels/united-kingdom/england/liverpool/',
              },
              {
                text: 'Glasgow',
                link: '/en-gb/hotels/united-kingdom/scotland/glasgow/',
              },
            ],
          },
          {
            title: 'Getaways',
            links: [
              {
                text: 'Last-minute',
                link: '/en-gb/hotels/last-minute/',
              },
              {
                text: 'Spa breaks',
                link: '/en-gb/hotels/spa-breaks/',
              },
              {
                text: 'Theme park hotels',
                link: '/en-gb/hotels/theme-park-hotels/',
              },
              {
                text: 'Bed and breakfast',
                link: '/en-gb/hotels/bed-and-breakfast/',
              },
            ],
          },
          {
            title: 'Europe',
            links: [
              {
                text: 'Amsterdam',
                link: '/en-gb/hotels/netherlands/amsterdam/',
              },
              {
                text: 'Disneyland',
                link: '/en-gb/hotels/france/disneyland-paris/',
              },
              {
                text: 'Paris',
                link: '/en-gb/hotels/france/paris/',
              },
              {
                text: 'France',
                link: '/en-gb/hotels/france/',
              },
              {
                text: 'Portugal',
                link: '/en-gb/hotels/portugal/',
              },
              {
                text: 'Albufeira',
                link: '/en-gb/hotels/portugal/algarve/albufeira/',
              },
            ],
          },
          {
            title: 'Mediterranean',
            links: [
              {
                text: 'Benidorm',
                link: '/en-gb/hotels/spain/benidorm/',
              },
              {
                text: 'Barcelona',
                link: '/en-gb/hotels/spain/barcelona/',
              },
              {
                text: 'Spain',
                link: '/en-gb/hotels/spain/',
              },
              {
                text: 'Tenerife',
                link: '/en-gb/hotels/spain/canary-islands/tenerife/',
              },
              {
                text: 'Majorca',
                link: '/en-gb/hotels/spain/balearic-islands/majorca/',
              },
              {
                text: 'Malta',
                link: '/en-gb/hotels/malta/',
              },
            ],
          },
          {
            title: 'USA',
            links: [
              {
                text: 'New York',
                link: '/en-gb/hotels/united-states/new-york/',
              },
              {
                text: 'Las Vegas',
                link: '/en-gb/hotels/united-states/las-vegas/',
              },
              {
                text: 'Orlando',
                link: '/en-gb/hotels/united-states/orlando/',
              },
              {
                text: 'All USA',
                link: '/en-gb/hotels/united-states/',
              },
              {
                text: 'Florida',
                link: '/en-gb/hotels/united-states/florida/',
              },
              {
                text: 'San Francisco',
                link: '/en-gb/hotels/united-states/san-francisco/',
              },
            ],
          },
          {
            title: 'Asia',
            links: [
              {
                text: 'Dubai',
                link: '/en-gb/hotels/united-arab-emirates/dubai/',
              },
              {
                text: 'Singapore',
                link: '/en-gb/hotels/singapore/',
              },
              {
                text: 'Maldives',
                link: '/en-gb/hotels/maldives/',
              },
              {
                text: 'Thailand',
                link: '/en-gb/hotels/thailand/',
              },
              {
                text: 'Pattaya',
                link: '/en-gb/hotels/thailand/pattaya/',
              },
              {
                text: 'Phuket',
                link: '/en-gb/hotels/thailand/phuket/',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'London',
            subText: '',
            image: 'navigation-cards/hotels/united-kingdom-england-london.jpg',
            link: '/en-gb/hotels/united-kingdom/england/london/',
          },
          {
            tag: '',
            title: 'Last-minute',
            subText: '',
            image: 'navigation-cards/hotels/last-minute.jpg',
            link: '/en-gb/hotels/last-minute/',
          },
        ],
      },
      {
        channel: 'Travel insurance',
        channelLink: '/en-gb/travel-insurance/',
        subnav: [
          {
            title: 'Popular policies',
            links: [
              {
                text: 'Single-trip',
                link: '/en-gb/travel-insurance/single-trip/',
              },
              {
                text: 'Long-stay',
                link: '/en-gb/travel-insurance/long-stay/',
              },
              {
                text: 'Annual multi-trip',
                link: '/en-gb/travel-insurance/annual-multi-trip/',
              },
              {
                text: 'Family',
                link: '/en-gb/travel-insurance/family/',
              },
              {
                text: 'Group',
                link: '/en-gb/travel-insurance/group/',
              },
              {
                text: 'Cruise',
                link: '/en-gb/travel-insurance/cruise/',
              },
            ],
          },
          {
            title: 'Policies by age',
            links: [
              {
                text: 'Under 18',
                link: '/en-gb/travel-insurance/under-18/',
              },
              {
                text: 'Student',
                link: '/en-gb/travel-insurance/student/',
              },
              {
                text: 'Over 65',
                link: '/en-gb/travel-insurance/over-65/',
              },
              {
                text: 'Over 70',
                link: '/en-gb/travel-insurance/over-70/',
              },
              {
                text: 'Over 80',
                link: '/en-gb/travel-insurance/over-80/',
              },
            ],
          },
          {
            title: 'Medical policies',
            links: [
              {
                text: 'Medical conditions',
                link: '/en-gb/travel-insurance/medical-conditions/',
              },
              {
                text: 'Insurance during pregnancy',
                link: '/en-gb/travel-insurance/pregnant/',
              },
              {
                text: 'Cancer',
                link: '/en-gb/travel-insurance/cancer/',
              },
            ],
          },
          {
            title: 'Other policies',
            links: [
              {
                text: 'Winter sports',
                link: '/en-gb/travel-insurance/winter-sports/',
              },
              {
                text: 'Gap year',
                link: '/en-gb/travel-insurance/gap-year/',
              },
              {
                text: 'Backpacker',
                link: '/en-gb/travel-insurance/student/',
              },
              {
                text: 'Flight insurance',
                link: '/en-gb/travel-insurance/flight/',
              },
              {
                text: 'Cruise insurance',
                link: '/en-gb/travel-insurance/cruise/',
              },
            ],
          },
          {
            title: 'Destinations',
            links: [
              {
                text: 'UK',
                link: '/en-gb/travel-insurance/united-kingdom/',
              },
              {
                text: 'India',
                link: '/en-gb/travel-insurance/india/',
              },
              {
                text: 'Worldwide',
                link: '/en-gb/travel-insurance/worldwide/',
              },
              {
                text: 'Turkey',
                link: '/en-gb/travel-insurance/turkey/',
              },
              {
                text: 'European',
                link: '/en-gb/travel-insurance/european/',
              },
              {
                text: 'USA',
                link: '/en-gb/travel-insurance/united-states/',
              },
            ],
          },
          {
            title: 'Need help?',
            links: [
              {
                text: 'What are you covered for?',
                link: '/en-gb/travel-insurance/what-does-travel-insurance-cover-you-for/',
              },
              {
                text: 'Travel insurance explained',
                link: '/en-gb/travel-insurance/explained/',
              },
              {
                text: 'Cancellation cover',
                link: '/en-gb/travel-insurance/cancellation-cover/',
              },
              {
                text: 'Tips and advice',
                link: '/en-gb/travel-insurance/tips/',
              },
              {
                text: 'GHIC explained',
                link: '/en-gb/blog/travel-advice/what-is-a-ghic-the-global-health-insurance-card-explained/',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'Single-trip',
            subText: '',
            image: 'navigation-cards/travel-insurance/single-trip.jpg',
            link: '/en-gb/travel-insurance/single-trip/',
          },
          {
            tag: '',
            title: 'European',
            subText: '',
            image: 'navigation-cards/travel-insurance/european.jpg',
            link: '/en-gb/travel-insurance/european/',
          },
        ],
      },
      {
        channel: 'Travel extras',
        channelLink: '/en-gb/extras/',
        subnav: [
          {
            title: 'Travel extras',
            links: [
              {
                text: 'All travel extras',
                link: '/en-gb/extras/',
              },
              {
                text: 'Airport transfers',
                link: '/en-gb/airport-transfers/',
              },
              {
                text: 'Airport parking',
                link: '/en-gb/extras/airport-parking/?agent=AW698',
              },
              {
                text: 'Airport hotels',
                link: '/en-gb/extras/airport-hotels/?agent=AW698',
              },
              {
                text: 'Airport lounges',
                link: '/en-gb/extras/airport-lounges/?agent=AW698',
              },
              {
                text: 'Travel insurance',
                link: '/en-gb/travel-insurance/',
              },
              {
                text: 'Car hire excess insurance',
                link: '/en-gb/car-hire/excess-insurance/',
              },
            ],
          },
          {
            title: 'Villa holidays',
            links: [
              {
                text: 'All villa holidays',
                link: '/en-gb/villa-holidays/',
              },
              {
                text: 'Spain villas',
                link: '/en-gb/villa-holidays/spain/',
              },
              {
                text: 'Portugal villas',
                link: '/en-gb/villa-holidays/portugal/',
              },
              {
                text: 'USA villas',
                link: '/en-gb/villa-holidays/united-states/',
              },
              {
                text: 'France villas',
                link: '/en-gb/villa-holidays/france/',
              },
              {
                text: 'Lanzarote villas',
                link: '/en-gb/villa-holidays/spain/canary-islands/lanzarote/',
              },
              {
                text: 'Croatia villas',
                link: '/en-gb/villa-holidays/croatia/',
              },
              {
                text: 'Italy villas',
                link: '/en-gb/villa-holidays/italy/',
              },
            ],
          },
          {
            title: 'Cottage holidays',
            links: [
              {
                text: 'All cottage holidays',
                link: '/en-gb/holiday-cottages/',
              },
              {
                text: 'Cornwall',
                link: '/en-gb/holiday-cottages/cornwall/',
              },
              {
                text: 'Devon',
                link: '/en-gb/holiday-cottages/devon/',
              },
              {
                text: 'Dorset',
                link: '/en-gb/holiday-cottages/dorset/',
              },
              {
                text: 'Lake District',
                link: '/en-gb/holiday-cottages/lake-district/',
              },
              {
                text: 'Scotland',
                link: '/en-gb/holiday-cottages/scotland/',
              },
              {
                text: 'Wales',
                link: '/en-gb/holiday-cottages/wales/',
              },
              {
                text: 'Norfolk',
                link: '/en-gb/holiday-cottages/norfolk/',
              },
              {
                text: 'With hot tubs',
                link: '/en-gb/holiday-cottages/with-hot-tub/',
              },
              {
                text: 'Pet-friendly',
                link: '/en-gb/holiday-cottages/dog-friendly/',
              },
            ],
          },
          {
            title: 'More extras',
            links: [
              {
                text: 'Fast track',
                link: '/en-gb/extras/fast-track/?agent=AW698',
              },
              {
                text: 'Airport hotels with parking',
                link: '/en-gb/extras/airport-hotels-with-parking/?agent=AW698',
              },
              {
                text: 'Majorca transfer',
                link: '/en-gb/airport-transfers/majorca/',
              },
              {
                text: 'Malaga transfers',
                link: '/en-gb/airport-transfers/malaga/',
              },
              {
                text: 'Barcelona transfers',
                link: '/en-gb/airport-transfers/barcelona/',
              },
              {
                text: 'Alicante transfers',
                link: '/en-gb/airport-transfers/alicante/',
              },
            ],
          },

          {
            title: 'Tips and advice',
            links: [
              {
                text: 'What is car hire excess insurance?',
                link: '/en-gb/blog/travel-advice/what-is-car-hire-excess-insurance-and-do-i-need-it/',
              },
              {
                text: 'How to get travel extras for less',
                link: '/en-gb/blog/travel-advice/luxury-holiday-extras-for-less/',
              },
              {
                text: 'How to get airport parking for less',
                link: '/en-gb/blog/travel-advice/how-to-get-the-best-deal-on-your-airport-parking/',
              },
              {
                text: 'Why book an airport transfer?',
                link: '/en-gb/blog/travel-advice/7-essential-reasons-to-book-an-airport-transfer/',
              },
              {
                text: 'How to travel in style on a budget',
                link: '/en-gb/blog/travel-advice/how-to-travel-through-the-airport-in-style-on-a-budget/',
              },
              {
                text: 'Airport tips for a stress-free holiday',
                link: '/en-gb/blog/travel-advice/eight-quick-tips-getting-airport-stress-free/',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'Airport transfers',
            subText: '',
            image: 'navigation-cards/travel-extras/airport-transfers.jpg',
            link: '/en-gb/airport-transfers/',
          },
          {
            tag: '',
            title: 'Airport parking',
            subText: '',
            image: 'navigation-cards/travel-extras/airport-parking.jpg',
            link: '/en-gb/extras/airport-parking/?agent=AW698',
          },
        ],
      },
      {
        channel: 'Blog',
        channelLink: '/en-gb/blog/',
        subnav: [
          {
            title: 'Travel advice',
            links: [
              {
                text: 'Last-minute booking tips',
                link: '/en-gb/blog/travel-advice/when-is-the-best-time-to-book-a-cheap-last-minute-holiday/',
              },
              {
                text: 'Money-saving flight hacks',
                link: '/en-gb/blog/travel-advice/ask-the-experts-flight-and-airport-hacks-that-save-time-and-money/',
              },
              {
                text: 'Passport renewal tips',
                link: '/en-gb/blog/travel-advice/passport-post-youd-better-hope/',
              },
              {
                text: 'Duty-free explained',
                link: '/en-gb/blog/travel-advice/how-much-duty-free-can-you-bring-into-the-uk/',
              },
              {
                text: 'Hand luggage tips',
                link: '/en-gb/blog/travel-advice/hand-luggage-tips/',
              },
              {
                text: 'Travel insurance FAQs',
                link: '/en-gb/blog/travel-advice/your-travel-insurance-questions-answered/',
              },
              {
                text: 'When to book holidays',
                link: '/en-gb/blog/travel-advice/holiday-vs-holiday-book-now-book-later/',
              },
              {
                text: 'Sustainable travel',
                link: '/en-gb/blog/travel-advice/how-to-make-your-holiday-more-sustainable/',
              },
            ],
          },
          {
            title: 'Holiday inspiration',
            links: [
              {
                text: 'Best all-inclusive escapes',
                link: '/en-gb/blog/inspiration/best-all-inclusive-destinations/',
              },
              {
                text: 'Winter sun destinations',
                link: '/en-gb/blog/inspiration/top-10-winter-sun-holiday-destinations/',
              },
              {
                text: 'Winter city breaks',
                link: '/en-gb/blog/inspiration/10-of-the-best-winter-city-breaks/',
              },
              {
                text: 'Staycation destinations',
                link: '/en-gb/blog/inspiration/great-britains-top-10-staycation-destinations/',
              },
              {
                text: "Europe's best cities",
                link: '/en-gb/blog/inspiration/best-cities-to-visit-in-europe-this-year/',
              },
              {
                text: 'Top 10 US cities',
                link: '/en-gb/blog/inspiration/top-ten-cities-usa/',
              },
              {
                text: 'Solo travel ideas',
                link: '/en-gb/blog/inspiration/10-best-destinations-first-time-solo-travel/',
              },
              {
                text: 'Alternative Spain',
                link: '/en-gb/blog/inspiration/alternative-spanish-cities-to-visit-after-barcelona/',
              },
            ],
          },
          {
            title: 'Expert guides',
            links: [
              {
                text: 'Balearics vs Canaries',
                link: '/en-gb/blog/guides/balearic-vs-canaries/',
              },
              {
                text: "Turkey's top resorts",
                link: '/en-gb/blog/guides/talking-turkey-which-resort-is-right-for-you/',
              },
              {
                text: 'Where to go in Greece',
                link: '/en-gb/blog/guides/where-to-go-in-greece-for-every-type-of-traveller/',
              },
              {
                text: 'Secret Algarve',
                link: '/en-gb/blog/guides/secret-algarve-how-to-escape-the-tourist-trail/',
              },
              {
                text: 'Caribbean islands guide',
                link: '/en-gb/blog/guides/which-caribbean-island-is-best/',
              },
              {
                text: 'Where to stay in Mexico',
                link: '/en-gb/blog/guides/beyond-cancun-great-resorts-in-mexico/',
              },
              {
                text: "Thailand's best islands",
                link: '/en-gb/blog/guides/best-islands-thailand/',
              },
              {
                text: 'New York like a local',
                link: '/en-gb/blog/guides/new-york-survival-guide/',
              },
            ],
          },
          {
            title: 'Where to go when',
            links: [
              {
                text: 'Where to go in January',
                link: '/en-gb/blog/inspiration/where-to-go-january/',
              },
              {
                text: 'Where to go in February',
                link: '/en-gb/blog/inspiration/where-to-go-february/',
              },
              {
                text: 'Where to go in March',
                link: '/en-gb/blog/inspiration/where-to-go-in-march/',
              },
              {
                text: 'Where to go in April',
                link: '/en-gb/blog/inspiration/where-to-go-april/',
              },
              {
                text: 'Where to go in May',
                link: '/en-gb/blog/inspiration/where-to-go-may/',
              },
              {
                text: 'Where to go in June',
                link: '/en-gb/blog/inspiration/where-to-go-june/',
              },
              {
                text: 'Where to go in July',
                link: '/en-gb/blog/inspiration/where-to-go-july/',
              },
              {
                text: 'Where to go in August',
                link: '/en-gb/blog/inspiration/where-to-go-august/',
              },
              {
                text: 'Where to go in September',
                link: '/en-gb/blog/inspiration/where-to-go-september/',
              },
              {
                text: 'Where to go in October',
                link: '/en-gb/blog/inspiration/where-to-go-october/',
              },
              {
                text: 'Where to go in November',
                link: '/en-gb/blog/inspiration/where-to-go-november/',
              },
              {
                text: 'Where to go in December',
                link: '/en-gb/blog/inspiration/where-to-go-december/',
              },
            ],
          },
          {
            title: 'Budget travel ',
            links: [
              {
                text: 'Money-saving travel tips',
                link: '/en-gb/blog/travel-advice/10-tips-for-getting-more-out-of-your-holiday-money/',
              },
              {
                text: 'UK holidays for less',
                link: '/en-gb/blog/travel-advice/how-to-save-on-a-uk-holiday/',
              },
              {
                text: 'Cheap holiday extras',
                link: '/en-gb/blog/travel-advice/luxury-holiday-extras-for-less/',
              },
              {
                text: 'Cheap city breaks in Europe',
                link: '/en-gb/blog/inspiration/7-eastern-european-cities-that-are-still-cheap/',
              },
              {
                text: 'Budget Canary Islands',
                link: '/en-gb/blog/guides/how-to-do-the-canary-islands-on-a-budget/',
              },
              {
                text: 'Budget Majorca',
                link: '/en-gb/blog/guides/how-to-do-majorca-on-a-budget/',
              },
              {
                text: 'Budget Crete',
                link: '/en-gb/blog/guides/how-to-do-crete-on-a-budget/',
              },
              {
                text: 'Budget Algarve',
                link: '/en-gb/blog/guides/how-to-do-the-algarve-on-a-budget/',
              },
            ],
          },
          {
            title: 'Family holidays ',
            links: [
              {
                text: 'Money-saving tips for families',
                link: '/en-gb/blog/travel-advice/saving-money-on-family-holidays/',
              },
              {
                text: 'Best family destinations',
                link: '/en-gb/blog/inspiration/top-ten-family-holiday-destinations/',
              },
              {
                text: 'Kid-friendly cities',
                link: '/en-gb/blog/inspiration/7-child-friendly-city-breaks/',
              },
              {
                text: 'Family staycation ideas',
                link: '/en-gb/blog/inspiration/best-uk-family-staycation-destinations/',
              },
              {
                text: 'Alternative family holidays',
                link: '/en-gb/blog/inspiration/best-alternative-family-holidays/',
              },
              {
                text: 'Family holiday rip-offs',
                link: '/en-gb/blog/travel-advice/7-ripoffs-when-travelling-with-children-and-how-to-avoid-them/',
              },
              {
                text: 'Kids luggage allowances',
                link: '/en-gb/blog/travel-advice/travelling-with-kids-know-your-luggage-allowances/',
              },
              {
                text: 'Term time travel explained',
                link: '/en-gb/blog/travel-advice/can-i-take-my-kids-on-holiday-during-term-time/',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'Last-minute booking tips',
            subText: '',
            image:
              'navigation-cards/blog/when-is-the-best-time-to-book-a-cheap-last-minute-holiday.jpg',
            link: '/en-gb/blog/travel-advice/when-is-the-best-time-to-book-a-cheap-last-minute-holiday/',
          },
          {
            tag: '',
            title: 'Best all-inclusive escapes',
            subText: '',
            image: 'navigation-cards/blog/best-all-inclusive-destinations.jpg',
            link: '/en-gb/blog/inspiration/best-all-inclusive-destinations/',
          },
        ],
      },
    ],
  },
};

export const headerTheme: HeaderTheme = {
  backgroundColor: 'white',
  logoColor: 'primary',
  logoHoverColor: 'primary_lighter4',
  dividerColor: 'grey2',
  navToggleBtnColor: 'black',
};

export const navigationTheme: NavigationTheme = {
  borderColor: 'primary',
  focusColor: 'primary',
  linkColor: 'black',
  linkHoverColor: 'black',
  chevronColor: 'primary',
  chevronHoverColor: 'primary',
  mobileChevronColor: 'primary',
  subnav: {
    headingColor: 'primary',
    chevronColor: 'primary',
  },
  card: {
    tagColor: 'primary',
  },
};
