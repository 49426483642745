import { ConfigButton } from '@supersonic/config';

const button: ConfigButton = {
  iconOnlyBorderRadius: '_1',
  primary: {
    backgroundColor: 'secondary',
    hoverColor: 'secondary_darker1',
    focusColor: 'secondary_lighter1',
    font: 'font-foxtrot font-foxtrot--bold',
  },
  secondary: {
    backgroundColor: 'white',
    borderColor: 'secondary',
    hoverColor: 'secondary_lighter4',
    focusColor: 'secondary_lighter4',
    font: 'font-foxtrot font-foxtrot--bold',
    textColor: 'secondary',
  },
  tertiary: {
    focusBorderColor: 'secondary',
    font: 'font-foxtrot font-foxtrot--bold',
    hoverColor: 'secondary_lighter4',
    textColor: 'secondary',
  },
  aux: {
    focusBorderColor: 'secondary',
    font: 'font-hotel',
    hoverColor: 'secondary_lighter4',
    textColor: 'secondary',
  },
};
export default button;
