import { BlogTheme } from '@supersonic/config';

export const blogTheme: BlogTheme = {
  tagColor: 'primary',
  fadeColor: 'grey6',
  focusColor: 'primary',
  imageCaptionColor: 'white',
  imageCaptionBackgroundColor: 'secondary_darker2_alpha9',
  imageCaptionBarColor: 'primary',
};
