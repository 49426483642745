import { ConfigHolidayGadget } from '@supersonic/config';

const holidayGadget: ConfigHolidayGadget = {
  backTab: {
    clickTripzApi: 'https://www.clicktripz.com/x/pas',
    desktopPlacementId: '3833-1',
    mobilePlacementId: '3928-1',
    siteId: 'icelolly',
    timestampLocalStorageKey: 'travelsupermarket-back-tab-timestamp',
  },
  passengersSelector: { fieldLabel: 'Travellers' },
  airportsSelector: { fieldLabel: 'Leaving from' },
  durationSelector: { fieldLabel: 'Duration' },
  destinationSelector: {
    showAnywhereDestination: true,
    fieldLabel: 'Going to',
    autocompleteApi: process.env.AUTOCOMPLETE_API_URL as string,
  },
  dateSelector: { fieldLabel: 'Departure date' },
  layout: {
    showAirportSelectorFirst: true,
  },
};

export default holidayGadget;
