import { ConfigExpertiseAuthorityTrust } from '@supersonic/config';

const expertiseAuthorityTrust: ConfigExpertiseAuthorityTrust = {
  siteHomepage: [
    {
      label: 'Luggage Tag',
      title: 'Compare cheap package holidays',
      description: 'Find your perfect getaway',
    },
    {
      label: 'ATOL',
      title: 'ATOL protected',
      description: 'Book your break with confidence',
      link: '/en-gb/holidays/atol-protection/',
      linkLabel: 'ATOL protection page',
    },
    {
      label: 'ABTA',
      title: 'ABTA Protected',
      description: 'Book your break with confidence',
    },
    {
      label: 'Handshake',
      title: 'Partnered with 20+ trusted brands',
      description: 'Search from the UK’s top travel providers',
    },
  ],
  carHire: [
    {
      label: 'Car',
      title: 'Compare 20+ trusted brands',
      description: 'Find your perfect rental car',
    },
    {
      label: 'MoneySuperMarket',
      title: 'Part of MoneySuperMarket',
      description: 'Saving you money for almost 20 years',
    },
    {
      label: 'Gas Pump',
      title: 'Fair fuel policy',
      description: 'Only pay for the fuel you use',
    },
    {
      label: 'Eye Slash',
      title: 'No hidden fees',
      description: 'Find flexible policies with our partners',
    },
  ],
  holidays: [
    {
      label: 'Sun horizon',
      title: 'Thousands of holidays',
      description: 'Compare 20+ trusted brands',
    },
    {
      label: 'ATOL',
      title: 'ATOL-protected holidays',
      description: 'Book your getaway with confidence',
      link: '/en-gb/holidays/atol-protection/',
      linkLabel: 'ATOL protection page',
    },
    {
      label: 'MoneySuperMarket',
      title: 'Part of MoneySuperMarket',
      description: 'Saving you money for almost 20 years',
    },
    {
      label: 'Chat dots',
      title: 'Expert travel advice',
      description: 'Get the latest holiday tips and inspiration',
    },
  ],
  flights: [
    {
      label: 'Airplane takeoff',
      title: 'Compare thousands of flights',
      description: 'Search all the top booking sites - in one place',
    },
    {
      label: 'MoneySuperMarket',
      title: 'Part of MoneySuperMarket',
      description: 'Saving you money for almost 20 years',
    },
    {
      label: 'Hand and coins',
      title: 'Fare assistant',
      description: 'See extra baggage and card charges upfront',
    },
    {
      label: 'Seal check',
      title: 'Trusted by travellers',
      description: 'Join millions of happy holidaymakers',
    },
  ],
  hotels: [
    {
      label: 'Compare hotels',
      title: 'Compare millions of hotel deals',
      description: 'Search all the top booking sites - in one place',
    },
    {
      label: 'MoneySuperMarket',
      title: 'Part of MoneySuperMarket',
      description: 'Saving you money for almost 20 years',
    },
    {
      label: 'Best price',
      title: 'Best price guarantee',
      description: 'No booking fee, no mark-up',
    },
    {
      label: 'Seal check',
      title: 'Trusted by travellers',
      description: 'Join millions of happy holidaymakers',
    },
  ],
  travelInsurance: [
    {
      label: 'Compare travel insurance',
      title: 'Compare 20+ trusted brands',
      description: 'More than 120 policies available',
    },
    {
      label: 'MoneySuperMarket',
      title: 'Part of MoneySuperMarket',
      description: 'Saving you money for almost 20 years',
    },
    {
      label: 'Fast free simple',
      title: 'Fast, free and simple',
      description: 'Get your quote in seconds',
    },
    {
      label: 'Tailored policies',
      title: 'Policies tailored to you',
      description: 'From single-trip to specialist cover',
    },
  ],
  cottages: [
    {
      label: 'Properties',
      title: 'Compare 60,000+ properties',
      description: 'Find your perfect stay',
    },
    {
      label: 'MoneySuperMarket',
      title: 'Part of MoneySuperMarket',
      description: "Saving you money with 15 years' experience",
    },
    {
      label: 'Best price',
      title: 'Best price quarantee',
      description: 'Get back the difference if you find a cheaper price online',
    },
    {
      label: 'Seal check',
      title: 'Trusted by travellers',
      description: 'Join thousands of happy customers',
    },
  ],
  airportTransfers: [
    {
      label: 'Destinations',
      title: '20,000+ destinations worldwide',
      description: 'Private and shared vehicles available',
    },
    {
      label: 'MoneySuperMarket',
      title: 'Part of MoneySuperMarket',
      description: "Saving you money with 15 years' experience",
    },
    {
      label: 'Best price',
      title: 'No hidden fees',
      description: 'Baggage, gratuities and tolls included',
    },
    {
      label: 'Seal check',
      title: 'Trusted by travellers',
      description: '26,000+ positive independent reviews',
    },
  ],
};
export default expertiseAuthorityTrust;
